<template>
  <header>
    <div class="menu_bar" :style="{display: menuBarStyle}">
        <a href="#" class="bt-menu" @click="showMenu"><span class="icon-list2"><img src="./images/iconoMenuBlancof9f9f9.svg"/></span></a>
      </div>
    <nav id="menuNav" :style="{display: navStyle}">
      <ul>
        <li>
          <router-link to="/">Bienvenidos</router-link>
        </li> 
		<li>
			<router-link to="/comoLlegar">Como llegar</router-link>
		</li>
		<li>
			<router-link to="/salamanca">Descubre salamanca</router-link>
		</li>
		<li>
			<router-link to="/evento">Evento</router-link>
		</li>

      </ul>
    </nav>
  </header>
  <div id="content">
	<router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

header {
	width:100%;
}
#router {
	margin-top: 100px;
}
#content {
	padding-top: 4.5em;
}

header nav {	
	margin: auto;
	position: fixed;
	width: 100%;
	background-color: #831418;
}

.menu_bar {
	position: relative;
}

header nav ul {
	overflow:hidden;
	list-style:none;
	display:inline-table;
}

header nav ul li {
	float:left;
}

header nav ul li a {
	color:#fff;
	padding:20px;
	display:block;
	text-decoration:none;
}

header nav ul li span {
	margin-right:10px;
}

header nav ul li a:hover {
	background:#8c0303;
}

section {
	padding:20px;
}

@media screen and (min-width:800px ) {
	.menu_bar{
		display:none;
	}

}

@media screen and (max-width:800px ) {
	header nav {
		width:80%;
		height:100%;
		left:-100%;
		margin:0;
		position: fixed;
		display: none;
	}

	header nav ul li {
		display:block;
		float:none;
		border-bottom:1px solid rgba(255,255,255, .3);
	}

	header nav ul {
		position: fixed;
		background-color: #831418;
		margin: 13% 10% 25% -61%;
        width: 100%;
		/*width: 100%;
		margin-left: -61%;*/
	}

	.menu_bar {
		display:block;
		width:100%;
		position: fixed;
	}

	.menu_bar .bt-menu {
		display:block;
		padding:20px;
		background:#831418;
		color:#fff;
		text-decoration:none;
		font-weight: bold;
		font-size:25px;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
	}

	.menu_bar span {
		float:right;
		font-size:40px;
	}
}

.containerApartado{
  margin-left: 15%;
  margin-right: 15%;
}
</style>

<script lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';

export default {
  setup() {
    const windowWidth = ref(window.innerWidth);
    const navStyle = ref("none");
	const menuBarStyle = ref("block");

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

	


    const showMenu = () => {
      if (windowWidth.value <= 800) {
        navStyle.value = navStyle.value === "none" ? "contents" : "none";
      }else{
		navStyle.value = "block";
	  }
    };

	const handleWindowResize = () => {
      if (windowWidth.value <= 800) {
		navStyle.value = "null";
		menuBarStyle.value = "block";
      } else {
        navStyle.value = "block";
		menuBarStyle.value = "none";
		}
    };

	watch(windowWidth, handleWindowResize);

	onMounted(() => {
      window.addEventListener('resize', updateWindowWidth);
      handleWindowResize(); // Llama a la función una vez para inicializar
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
    });

    return {
      windowWidth,
      navStyle,
	  menuBarStyle,
      showMenu
    };
  }
};
</script>
