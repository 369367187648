import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/evento',
    name: 'evento',
    component: () => import(/* webpackChunkName: "informacion" */ '../views/Evento.vue')
  },
  {
    path: '/asistencia',
    name: 'asistencia',
    component: () => import(/* webpackChunkName: "asistencia" */ '../views/ConfirmaTuAsistencia.vue')
  },
  {
    path: '/salamanca',
    name: 'salamanca',
    component: () => import(/* webpackChunkName: "salamanca" */ '../views/ConoceSalamanca.vue')
  },
  {
    path: '/regalo',
    name: 'regalo',
    component: () => import(/* webpackChunkName: "regalo" */ '../views/RegaloDeBoda.vue')
  },
  {
    path: '/comoLlegar',
    name: 'ComoLlegar',
    component: () => import(/* webpackChunkName: "llegar" */ '../views/ComoLlegar.vue')
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
